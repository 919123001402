<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="8">
                                Edit User
                            </CCol>
                            <CCol md="4" class="text-right">
                                
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Nip <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="nip" :value="data_user.nip" readonly="">
                                <!-- <div class="input-group mb-3">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label for=""><b>Nama <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="name" :value="data_user.name">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Email <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="email" :value="data_user.email">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Phone <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="phone" :value="data_user.phone">
                            </div>
                            <div class="form-group d-none">
                                <label for=""><b>NPWP <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="npwp" :value="data_user.npwp">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Jabatan <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" id="jabatan" :value="data_user.jabatan">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Role <span class="text-danger">*</span></b></label>
                                <v-select :options="list_role" label="name" v-model="roles_id"></v-select>
                                <input type="hidden" class="form-control" id="roles_id" v-model="roles_id.id">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Password <span class="text-danger">*</span></b></label>
                                <input type="password" class="form-control" id="password">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Konfirmasi Password <span class="text-danger">*</span></b></label>
                                <input type="password" class="form-control" id="password_confirmation">
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
const nullarray = [];
    export default {
        name: "EditUser",
        data(){
            return{
                list_role: nullarray,
                roles_id: {id: ""},
                userID: this.$route.params.userID,
                data_user: nullarray,
            }
        },
        methods: {
            storeProcess: function () {
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                axios.put(
                    this.apiLink + "api/crud/user",
                    {
                        id: this.userID,
                        name:$('#name').val(),
                        email:$('#email').val(),
                        nip:$('#nip').val(),
                        phone:$('#phone').val(),
                        npwp:$('#npwp').val(),
                        jabatan:$('#jabatan').val(),
                        roles_id: $('#roles_id').val(),
                        nib:'',
                        tipe_pemohon_id:'',
                        password:$('#password').val(),
                        password_confirmation:$('#password_confirmation').val(),
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master user segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                if (self.roles_id == 2) {
                                    self.$router.push({name: 'AllUser', params:{role: 'pemohon'}});
                                }
                                else{
                                    self.$router.push({name: 'AllUser', params:{role: 'admin'}});
                                }
                            }
                        });
                    }
                });
            },
        },
        created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Mohon tunggu sebentar...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Get Master Negara
            axios.get(this.apiLink + "api/master/role", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_role = response.data;
                var res_role_data = res_role.data;
                if (res_role.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_role.data.message
                    });
                }
                else{
                    this.list_role = res_role_data.master;
                }
            });
            // Detail User
            axios.get(this.apiLink + "api/crud/user", {
                params: {
                    id:this.userID,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_user = response.data;
                var res_user_data = res_user.data[0].data[0];
                if (res_user.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_user.data.message
                    });
                }
                else{
                    this.data_user = res_user_data;
                    this.roles_id = res_user_data.roles;
                    Swal.close();
                }
            });
        }
    };
</script>